body {
  /* background-color: #1C1E2A; */
  background-color: rgb(28, 30, 43);
  font-family: 'Poppins', sans-serif;
  padding-right: 0px !important;
}

h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: 'Poppins', sans-serif;
}

/* =============================Dashboard Page================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main {
  padding: 30px;
}

.dashboard-left-side-div-one-wrapper {
  background-color: #282F42;
  padding: 16px;
  border-radius: 5px;
}

.dashboard-left-side-div-one-wrapper h3 {
  color: white;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: flex-start;
}

.dashboard-left-side-div-one-inner-div {
  text-align: flex-end;
  margin-top: -8px;
  /* display: flex;
  align-items: flex-start; */
  justify-content: space-between;
  padding-left: 11px;
  padding-right: 0px;
  margin-top: 10px;
}

.dashboard-left-side-div-one-inner-div svg {
  margin-top: -10px;
}

.dashboard_page_battery_icon_wrapper {
  display: flex;
  align-items: flex-start;
}

.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
}

.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: white;
  font-size: 8px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dashboard-left-side-div-one-wrapper-battery-div {
  display: inline-block;
  text-align: center;
}

.dashboard-left-side-div-two-wrapper-h5 {
  color: white;
  font-weight: 300;
  font-size: 15px;
}

.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}

.dashboard_page_Order_Id_Wrapper {
  display: flex;
  justify-content: space-between;
  /* margin-bottom:5px; */
  margin-right: 22px;
  width: 100%;
}

.dashboard_page_Order_Id_Wrapper_inner_div {
  display: flex;
}

.dashboard_page_Order_Id_Wrapper_inner_div h3 {
  color: white;
  font-size: 18px;
  margin-bottom: 0px;
  align-self: center;
}

.dashboard-left-side-div-two-Map-Container-one {
  background-color: #282F42;
  height: 170px;
  overflow: scroll;
}

.dashboard-left-side-div-two-Map-Container-two {
  background-color: #282F42;
  /* height: 170px; */
  /* border:3px solid rgb(251,98,98) !important; */
  border-radius: 5px !important;
}

.dashboard-left-side-div-two-Map-Container-two h4 {
  color: white;
  font-size: 17px;
  text-align: center;
  padding: 19px 13px;
}

.connectionstatus-red {
  background-color: rgb(220, 53, 69);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}

.connectionstatus-green {
  background-color: rgb(113, 207, 98);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}

.dashboard_left_side_available_div {
  text-align: left;
}

.dashboard_left_side_available_div h4 {
  font-size: 10px;
  color: white !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar {
  background-color: #282F42;
  color: #fff !important;
  width: 10px;
}

.col-md-9.dashboard-right-side-div-wrapper-Main h2 {
  font-size: 22px;
  color: white;
  padding: 50px;
  padding-bottom: 0px;
  font-size: 17px;
  padding-left: 30px;
}

.leaflet-marker-icon {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}

@keyframes example {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  100% {
    border: none;
    transition: 1s ease-in-out;
    border-radius: 100%;
  }
}

@keyframes blink {
  0% {
    box-shadow: 0;
  }

  50% {
    box-shadow: .5;
  }

  100% {
    box-shadow: 1;
  }
}

.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.Dashboard_page_Map_wrapper_Main_whole {
  padding: 2rem;
  padding-top: 1rem;
  padding-right: 0px;
  padding-left: 0px;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track {
  color: #ffffff !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;

}

.dashboard-left-side-div-two-robot-button-Container.d-flex-center {
  background-color: #282F42;
  border-radius: 5px;
}

.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 5px;
  padding: 5px 24px;
  background-color: rgb(189, 196, 224);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid transparent;
}

.dashboard-right-side-div-wrapper-Main {
  background-color: #282F42;
  height: auto;
}

.Dashboard-Page-bottom-div-Main-wrapper {
  width: 100%;
  background-color: black;
  position: relative;
  top: 0;
  /* padding: 10px 25px; */
  /* z-index:999999 !important; */
}

.Dashboard_page_top_div_inner_div {
  margin-left: 20px;
  margin-right: 20px;
}

.Dashboard_page_bottom_div_inner_one_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}

.Dashboard_page_bottom_div_inner_two_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.Dashboard_page_bottom_div_inner_two_div button {
  margin-right: 10px;
  margin-left: 10px;
}

.Dashboard_page_bottom_div_inner_two_div_inner_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Dashboard_page_bottom_div_inner_two_div_inner_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashBoard-page-Main-Whole-wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.Dashboard_page_2d_map_button {
  position: absolute;
  bottom: 70px;
  left: 60px;
  background-color: #BDC4E0;
  border: 5px solid white;
  border-radius: 100%;
  padding: 17px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: white !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: -6px;
}

.direction_toggle_p {
  color: white !important;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-left: -6px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-span {
  color: rgb(189, 196, 224);
}

.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  /* background-color: #282F42;
  border: 2px solid #a2a2a2; */
  border-radius: 5px;
  padding: 4px 22px;
  font-size: 12px;
  color: white;
  font-weight: 200;
}

.Dashboard-page-teleop-button-disabled {
  background-color: #78797a !important;
}

.Dashboard-Page-Power-button {
  background-color: #282F42;
  border-radius: 30px;
  width: 35px !important;
  height: 35px !important;
  padding: 5px;
}

.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
  position: relative;
}

.Stop-button-dashhboard-page {
  background-color: rgb(228 51 41) !important;
  color: white !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border: none !important;
  padding: 6px 33px !important;
}

button#long-button {
  border: none;
  background-color: transparent;
  padding: 4px 0px;
}

.Dashboard_page_sidebar_button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 0;
  font-family: "Poppins", sans-serif;
  /* min-width: auto !important; */
}

.play-icon {
  cursor: pointer;
}

#long-menu ul {
  padding: 0px !important;
}

/* .Dashboard-page-teleop-button {
  border:2px solid rgb(220,53,69) !important; 
} */

.Dashboard_page_sidebar_menuItem {
  padding: 10px !important;
  border-bottom: 1px solid #eaeaea !important;
  /* justify-content: end !important; */
}

.Dashboard_page_sidebar_menuItem_last {
  padding: 10px !important;

}

.Error-state-teleop-ack-button {
  background-color: rgb(220, 53, 69) !important;
  color: white !important;
  font-size: 10px;
  position: absolute;
  padding: 1px 6px 2px 6px;
  right: 1rem;
  top: -10px;
  border-radius: 5px;
  z-index: 1;
  border: none !important;
}

.dashboard_page_Map_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
}

.dashboard-left-side-div-two-robot-button-Container button.Dashboardpage_CloseDoor_button {
  /* border: 2px solid rgb(189, 196, 224) !important; */
  /* color: rgb(189, 196, 224); */
  padding: 0;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
  text-align: left;
  outline: none;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
}

.Dashboardpage_CloseDoor_button {
  padding: 0;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent !important;
  color: inherit;
  text-align: left;
  outline: none;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
}

.Dashboardpage_OpenDoor_button {
  /* color: rgb(189, 196, 224); */
  padding: 0;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
  text-align: left;
  outline: none;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
}

.Teleop_completed_btn {
  flex: 1 1 auto;
  border: 2px solid rgb(189, 196, 224) !important;
  border-radius: 5px;
  padding-inline: 5px;
  color: #282F42 !important;
  background: rgb(189, 196, 224) !important;
  font-weight: bold !important;
  font-size: 0.80rem !important;
}

.Dashboard_page_OpenDoor_deactivate {
  padding: 0;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
  text-align: left;
  outline: none;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
  color: gray;
}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button {
  border: 2px solid #e43329;
  background: #bdc4e0;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 150px;
  font-weight: bold;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button.Teleop_completed_btn {
  border: 2px solid #e43329 !important;
  background: #FFF !important;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 200px;
  font-weight: bold;
  font-size: 16px !important;
  text-align: center;
}

.direction-toggle {
  display: flex;
  align-items: center;
}

.Map-Main-Whole-wrapper.border-blue-top {
  position: absolute;
  bottom: 146px;
  left: 69px;
  background-color: white;
  z-index: 999999999999 !important;
  border: 7px solid black;
  width: 347px;
  height: 270px;
}

/* =============================End================================================================================================================================================================================================================== */

/* ============================LoginPage========================================================================================================================================================================================================================== */

.Login-page-form {
  width: 35%;
  margin: auto;
  background: rgb(40, 47, 66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 25px;
  padding-top: 37px;
  border-radius: 8px;
  text-align: center;
}

input:focus {
  background-color: transparent !important;
}

/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.Login-page-form input:-webkit-autofill,
.Login-page-form input:-webkit-autofill:hover,
.Login-page-form input:-webkit-autofill:focus,
.Login-page-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(40, 47, 66) inset !important;
  -webkit-text-fill-color: rgb(189, 196, 224) !important;
}

.LoginPage-Main-wrapper {
  height: 100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width: 100%;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  width: 95%;
  margin: auto;
}

.Login-page-form-logo-image {
  width: 25%;
  display: block;
  margin: 0 auto;
}

img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:last-of-type {
  margin-left: -22px !important;
  margin-top: -30px !important;
}

.Loginpage-main-form-inner-div {
  margin-top: 44px !important;
}

.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1, 115, 212);
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}

.Loginpage\=-main-form {
  margin: 24px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 46px;
  margin-top: 46px;
}

.Login-page-form input {
  font-size: 19px !important;
  font-weight: 600 !important;
  color: rgb(189, 196, 224) !important;
  letter-spacing: 0px !important;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color: rgb(189, 196, 224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn {
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
  background-color: rgb(189, 196, 224) !important;
  padding: 10px !important;
  border-radius: 7px !important;
  width: 100% !important;
  border: none;
  font-weight: 700;
}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
  font-weight: 400;
  font-size: 10px;
  padding-left: 39px;
}

.Login-page-form .css-dmmspl-MuiFormGroup-root {
  padding-left: 15px
}

img.Logout-image {
  width: 100%;
  margin-left: 2px;
}

.Login-page-remember-checkbox {
  width: 25px;
  height: 25px;
}

.Login-page-remember-checkbox-span {
  margin: 10px;
  color: rgb(189, 196, 224);
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input:-webkit-autofill {
  background-color: transparent !important;
}

/* =============================End================================================================================================================================================================================================================== */

/* ==================SelectrFleetPage=======================================================================================================================================================   */
.SelectFleetPage-content-wrapper-Main {
  display: flex;
  flex-wrap: wrap;
}

.SelectFleetPage-inner-li-div {
  width: 330px;
  margin: 7px;
  padding: 11px;
  cursor: pointer;
  border-top: none !important;
  cursor: pointer;
  position: relative !important;
  background: rgb(40, 47, 66);
}

.SelectFleetPage-inner-li-div-two {
  width: 330px;
  /* margin: 7px;
  padding: 11px; */
  cursor: pointer;
  border-top: none !important;
  cursor: pointer;
  position: relative !important;
  background: rgb(40, 47, 66);
}

.SelectFleetPage-content-wrapper-Main.border-red {
  justify-content: flex-start;
  width: 407px;
  margin: 5px;
  background-color: rgb(40, 47, 66);
}

.Select-page-fleetName {
  color: #b7b7b7 !important;
  font-size: 16px;
}

.SelectFleetPage-content-wrapper-Main .Dashboard-content-div-one-left-side-h3 {
  text-align: flex-start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
  font-size: 13px;
  color: white !important;
}

.SelectFleetPage-content-wrapper-Main .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}

.container.SelectFleetPage-wrapper-Main {
  margin-top: 3rem;
}

.container.SelectFleetPage-wrapper-Main .SelectPageMain-Heading {
  margin: 22px;
  margin-left: 0px;
}

.SelectFleet-Page-p-tag {
  font-size: 21px;
}

.SelectFleet-Page-h5-tag {
  font-weight: 800;
}

.SelectFleetPage-inner-li-div-robot-id {
  margin-bottom: 0px;
}

.SelectPage-robotList-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* background: rgb(40,47,66); */

  width: 100%;
  justify-content: flex-start;
}

.Select-Page-Robot-Message-Heading {
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 15px;
}

.SelectFleet-page-Error-box-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

/* ==================End=======================================================================================================================================================   */
/* =====================Header===================================================================================================================================================================== */
.Header-Add-new-order-btn {
  border-radius: 30px;
}

.Header-Main-wrapper {
  /* box-shadow: 0px 16px 14px 5px lightgrey; */
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  padding: 14px 65px;
}

.HeaderMain-logo-image {
  width: auto;
  height: 34px;
  min-width: 221px;
}

/* .HeaderMain-logo-image {
  width: 7%;
} */

button.Header-Add-new-order-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  background-color: rgb(1, 115, 212) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}

.container-fluid.SelectFleetPage-wrapper-Main {
  padding-left: 30px;
  padding-right: 30px;
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}

.row.justify-content-between {
  justify-content: flex-end;
}

/* =========================End================================================================================================================================================================================= */
/* =============================Disengage Modal======================================================================================================================================================================================== */
.Modal-wrapper-p {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 1.6000000238px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

/* ==================================End====================================================================================================================================================================================================================== */
.No-robots-available {
  font-size: 13px;
  color: white;
}

.RobotActions-content-wrapper-div {
  color: #ffffff !important;
  padding-top: 10px;
  border-radius: 5px;
  text-align: left;
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.RobotActions-content-wrapper-div h3 {
  font-size: .85rem;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.RobotActions-content-wrapper-div p {
  font-size: 13px;
  margin-bottom: 0px;
}

.RobotActions-button-wrapper {
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.RobotActions-button-wrapper button {
  border: none;
  color: white !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 15px;
  margin-top: 5px;
}

.RobotActions-Start-btn {
  background-color: rgb(113 207 98);
}

.RobotActions-Start-btn-disabled {
  background-color: rgb(117, 189, 134);
  color: rgb(223, 219, 219) !important;
}

.RobotActions-cancel-btn {
  background-color: rgb(220, 53, 69);
}

/* =============================Responsive================================================================================================================================================================================================================== */
/* =============================End================================================================================================================================================================================================================== */
#map {
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  width: 100%;
}

@keyframes example {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  100% {
    border: none;
    transition: 1s ease-in-out;
    border-radius: 100%;
  }
}

.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}

/* .gm-style div:nth-child(2) div:nth-child(1) div:nth-child(2) div:nth-child(1) {
    position: absolute;
    left: 0px;
    top: -7px !important;
    z-index: 30;
} */
.battery-icon-css {
  width: 35px;
  position: relative;
}

.battery-icon-css:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0px;
}

.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: white;
  text-align: center;
  padding: 0px;
}

.RobotActions_popup_content_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(189, 196, 224);
  margin-top: 26px;
  margin-bottom: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.RobotActions_popup_content_wrapper p {
  font-size: 16px !important;
  color: #282F42;
}

.RobotActions_popup_content_wrapper h4 {
  color: #282F42;
  color: #282F42;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.Cancel-order-Modal-Mainwrapper {
  padding: 32px;
  padding-right: 0px;
  padding-left: 0px
}

.Cancel-order-Modal-Mainwrapper h5 {
  color: rgb(40, 167, 69);
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}

.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
}

.Modal-button-Main-btn {
  border-radius: 7px !important;
  border: 1px solid white !important;
  color: white !important;
  padding: 8px 55px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
}

.yes-red-active {
  background: white !important;
  /* color: black !important; */
}

.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_teleopmoving {
  background-color: #7859bc;
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid #7859bc; */
}

.teleopMoving_bordertop {
  border: 4px solid #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_autorunmoving {
  background-color: #40B4D0;
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid #40B4D0; */
}

.AutoRun_moving {
  border: 4px solid #40B4D0
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_state_Auto_Obstacle-Teleop_Obstacle {
  background-color: #e6883c;
  /* border-radius: 10px; */
  /* padding: 10px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid #e6883c; */
}

.AutoObstacle_teleop_obstacle_bordertop {
  border: 4px solid #e6883c
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_autoSystemFault {
  background-color: rgb(228 51 41);
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid rgb(228 51 41); */
}

.AutoSystem_fault_error_bordertop {
  border: 4px solid rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_payloadState {
  background-color: #f0b156;
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid #f0b156; */
}

.orange_border {
  border: 4px solid #f0b156 !important;
}

.payloadState_bordertop {
  border: 4px solid #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_teleop_Autorun, .robotRunningState_state_Teleop_Autorun {
  background-color: rgb(113, 207, 98);
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
  /* border: 2px solid rgb(113, 207, 98); */
}

.Teleop_autoRun_borderTop {
  border: 4px solid rgb(113, 207, 98);
}

.robotRunningState_default {
  background-color: #a2a2a2;
  /* border: 2px solid #a2a2a2; */
  /* border-radius: 5px; */
  /* padding: 8px 25px; */
  /* font-size: 15px; */
  color: white;
  font-weight: bold;
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
}

.defaultBorderTop {
  border: 4px solid #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
}

.tools {
  text-align: flex-end;
  position: absolute;
  z-index: 999999;
  right: 20px;
  display: flex;
}

.tools button {
  background: #3D4665 !important;
  font-weight: 600;
  margin: 5px;
  color: #BCC4DF;
  border: none;
  padding: 2px 12px;
  border-radius: 5px;
}

.Dashboard-content-div-one-left-side-h3 {
  color: white;
  text-align: left !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 25px;
  margin-bottom: 0px;
}

button.Dashboard_page_2d_map_button {
  display: none !important;
}

.reset_diagonostics_button {
  background: transparent;
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
}

.Estop_reset_diagnostics_wrapper {
  display: flex;
}


/* ============================Responsive=======================================================================================================================================================================================================================================*/
@media only screen and (max-width: 700px) {
  .Dashboard_page_bottom_div_inner_two_div_inner_one {
    display: none !important;
  }

  .Estop_reset_diagnostics_wrapper {
    display: none !important;
  }

  .Dashboard_page_bottom_div_inner_two_div {
    justify-content: flex-end;
    width: 25%;
  }

  .Dashboard_page_bottom_div_inner_one_div {
    width: 100%;
  }

  .Dashboard_page_whole_wrapper {
    flex-direction: column-reverse !important;
  }

  .col-md-9.dashboard-right-side-div-wrapper-Main {
    height: 62vh;
  }

  .col-md-9.dashboard-right-side-div-wrapper-Main>div>div {
    height: 450px !important;
  }

  .Online_offline_div {
    align-self: unset !important;
  }

  .Login-page-form {
    width: 90%;
  }

  /* .HeaderMain-logo-image {
    width: 30%;
  } */

  .container-fluid.SelectFleetPage-wrapper-Main {
    padding-left: 40px;
    padding-right: 40px;
  }

  .col-md-3.dashboard-left-side-div-wrapper-Main {
    margin-top: 45px;
  }
}

/* ============================End=======================================================================================================================================================================================================================================*/

.nav-main {
  /* width: 100%; */
  /* height: 73px; */
  /* background: #000000 0% 0% no-repeat padding-box; */
  opacity: 1;
  color: white;
  display: grid;
  align-items: center;
  /* min-width: 284px; */
}

.nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
}

.white-line {
  border: 1px solid #bec4de;
  opacity: 1;
}

.nav-right {
  display: flex;
  flex-direction: row;
  opacity: 1;
  gap: 5px;
}

.nav-right-1 {
  display: flex;
  flex-direction: row;
}

.nav-right-1-info {
  color: #ffffff;
  opacity: 1;
  padding-left: 1rem;
}

.nav-right-1-logo {
  /* background: transparent url("../../assets/user.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
  display: flex;
  align-items: center;
}

.nav-right-2-logo {
  /* background: transparent url("../../assets/switch.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
  display: flex;
  align-items: center;
}

.user-name {
  margin: 0px;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 1px;
}

.user-email {
  margin: 0px;
}

@media only screen and (max-width: 976px) {
  .nav-items {
    padding-inline: 17px;
  }
}

.home-main {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
  /* padding-inline: 4rem;
  padding-block: 2rem; */
}

@media only screen and (max-width: 976px) {
  .home-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* padding-inline: 17px; */
  }
}

.teleop-p {
  display: flex;
  flex-direction: row;
  background-color: #E96C67;
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 11px;
  padding: 1.2px 9px;
  font-weight: 100;
}

@media screen and (max-width: 327px) {
  .teleop-p {
    font-size: 5px;
  }
}

@media screen and (max-width: 269px) {
  .inner-card-items {
    display: grid !important;
    gap: 2rem;
  }

  .card-outer-items {
    display: grid !important;
    gap: 1rem
  }
}

.card {
  /* top: 54px; */
  width: 100%;
  background: #292f40 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  /* position: absolute; */
  /* padding: 2rem; */
}

.card-items {
  padding: 26px;
}

.card-outer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-item-1 {
  width: 81px;
  height: 20px;
  text-align: left;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 0px;
  /* color: #e96c67; */
  opacity: 1;
  display: flex;
  gap: 3.5px;
  align-items: center;
}

.card-item-2 {
  top: 213px;
  left: 81px;
  width: 65px;
  height: 20px;
  text-align: left;
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0px;
  color: #71cf62;
  opacity: 1;
}

.card-item-3 {
  width: 60px;
  height: 25px;
  opacity: 1;
  color: white;
  display: flex;
  gap: 11px;
  align-items: center;
  padding-top: 4px;
}

.cards {
  width: 438px;
  /* top: 3rem;
  left: 4rem; */
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-outer-right {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.card-outer-left {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.card-white-line {
  border: 1px solid #bec4de;
  opacity: 1;
  margin-top: 19px;
  margin-bottom: 19px;
}

.card-item-right {
  display: flex;
  /* gap: 5px; */
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: space-between;
}

.card-item-right-1 {
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: flex-end;
}

.signal-icon {
  font-size: 23px;
}

.play-pause-icon {
  font-size: 18px;
}

.inner-card-items {
  display: flex;
  justify-content: space-between;
}

.circle-icons {
  display: flex;
  flex-direction: row;
}

.circle-icon:not(:first-child) {
  margin-left: -6px;
}

.location-h3 {
  font: normal normal bold 18px/16px Poppins;
  margin: 0px;
  letter-spacing: 1px;
}

.card-total-robots {
  font: normal normal bold 30px/16px Poppins;
  margin: 0px;
}

.battery-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 976px) {
  .cards {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }

  .card-item-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}


.cus-battery-icon {
  display: flex;
  border: 1.5px solid white;
  width: 21px;
  height: 11px;
  border-radius: 4px;
  padding-inline: 2px;
  padding-block: 2px;
  position: relative;
  align-items: center;
}

.cus-battery-part {
  height: 6px;
  width: 3.5px;
  background: white;
  border-top-right-radius: 100rem;
  border-bottom-right-radius: 100rem;
  position: absolute;
  left: 18.5px;
}

.cus-inner-battery {
  background: #88cd6f;
  height: 100%;
  /* width: 30%; */
  border-radius: 2px;
  align-items: center;
  justify-content: center;
}

.card__item {
  cursor: pointer;
}

.robot__teleop__details {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 40%; */
  background: #292F40 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.robot_teleop__details__activate {
  border: 3px solid #735AB6;
}

.robot_teleop__details__deactivate {
  border: 3px solid #f0b156 !important;
}

.robot__teleop__details__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 5px; */
  gap: 5px
}

.robot__teleop {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  /* width: 59%; */
  height: 40px;
  /* background: #735AB6 0% 0% no-repeat padding-box;
  border: 2px solid #735AB6; */
  border-radius: 5px;
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.robot__teleop__ack {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  /* width: 29%; */
  height: 40px;
  border: 2px solid #E96C67;
  border-radius: 5px;
  text-align: center;
  font: normal normal bold .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
}

.robot__teleop__location {
  text-align: left;
  font: normal normal normal .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.robot__order__id {
  text-align: left;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  margin-bottom: 5px;
  color: #FFFFFF;
  opacity: 1;
}

.robot__no__action {
  text-align: left;
  font: normal normal normal .75rem/16px Poppins;
  letter-spacing: 0px;
  margin-bottom: 5px;
  color: #FFFFFF;
  opacity: 1;
}

.no__actions {
  margin-top: 5px;
  margin-bottom: 5px;
}

.robot__actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  /* margin-bottom: 10px; */
}

.robot__action {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #88CD6F 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 35px;
  width: 40%;
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.teleop__controller,
.teleop__watching {
  display: flex;
  align-items: flex-end;
  text-align: center;
  font: normal normal normal .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.teleop__watching strong {
  margin: 0 3px;
  text-align: center;
  font: normal normal normal .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.watching__icon {
  color: #BEC4DE;
  width: 15px;
  height: 15px;
}

.users__info__panel {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #bec4de 0% 0% no-repeat padding-box;
  border-radius: 7px;
}

.users__info {
  width: 100%;
  height: 60%;
  min-width: 40px;
  background: #292f40 0% 0% no-repeat padding-box;
  border: 1px solid #bec4de;
  border-radius: 7px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.game__controller__status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.current__user {
  text-align: center;
  font: normal normal bold .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #BEC4DE;
  opacity: 1;
}

.circle__icons {
  display: flex;
  flex-direction: row;
}

.circle__icon:not(:first-child) {
  margin-left: -6px;
}

.unlock__btn,
.request__access__btn {
  width: 100%;
  min-width: 30px;
  height: 40%;
  background: #bec4de 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal bold 18px/16px Poppins;
  letter-spacing: 0px;
  /* color: #292F40; */
  cursor: pointer;
  opacity: 1;
}

.control__btn__text {
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #8d91a3;
}

.controller__status {
  text-align: left;
  font: normal normal bold .65rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.game__controller__status img {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 1136px) {
  .robot__teleop {
    font-size: .65rem;
  }

  .robot__teleop__ack {
    font-size: .55rem;
  }

  .robot__teleop__location,
  .robot__order__id {
    font-size: 0.60rem;
  }

  .robot__action {
    font-size: 0.60rem;
  }

  /* .watching__icon {
      width: 15px;
      height: 15px;
  } */

  .teleop__watching {
    font-size: .60rem;
  }

  .teleop__watching strong {
    font-size: 0.70rem;
  }

  .robot__action {
    padding: 0px 10px;
  }

  .game__controller__status img {
    width: 20px;
    height: 20px;
  }

  .controller__status {
    font-size: 0.55rem;
  }

  .current__user {
    font-size: 0.60rem;
  }

  .main-circle {
    width: 20px;
    height: 20px;
  }

  .users__info {
    padding: 5px;
  }

  .inner-text {
    font-size: 0.50rem;
  }

  .unlock__btn,
  .request__access__btn {
    font-size: 0.80rem;
  }
}

.main__controls {
  background-color: #1c1e2b;
  padding: 10px;
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  /* height: max-content; */
  display: flex;
  flex-direction: column;
}

.camera {
  color: white;
  top: 0px;
  width: 629px;
  height: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameras {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30vh;
}

.h1-text-upper-camera {
  top: 111px;
  left: 135px;
  text-align: center;
  font: normal normal bold 28px/16px Poppins;
  letter-spacing: 0px;
  color: #bec4de;
  opacity: 1;
  margin: 0px;
}

.robot-controll-id {
  position: fixed;
  color: rgb(0, 0, 0);
  background-color: #8e93a6;
  padding: 9px;
  display: flex;
  align-items: center;
  gap: 14px;
  border-bottom-right-radius: 7px;
  z-index: 100;
  width: 100%;
}

.main-contents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-panel {
  /* top: 711px;
  left: 99px; */
  width: 226px;
  height: 47px;
  background: #292f40 0% 0% no-repeat padding-box;
  border: 1px solid #bec4de;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-block: 4px;
  padding-inline: 11px;
  margin-left: 22px;
}

.top-status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  padding: 1rem 1rem;
  justify-content: space-between;
}

.other-users {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-controller-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.current-user {
  color: white;
}

.watching {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-top: 6px;
}

.watching-total {
  font: normal normal bold 30px/16px Poppins;
}

.controll-button {
  width: 129px;
  height: 57px;
  background: #bec4de 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.controll-button-text {
  top: 726px;
  left: 352px;
  width: 92px;
  height: 20px;
  /* UI Properties */
  text-align: center;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 0px;
  color: #8d91a3;
}

.status-panel {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  gap: 30px;
}

.inner-stuff {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.battery-status {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 21px;
}

.speed-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.weather-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.battery-status-icon {
  font-size: 52px;
}

.switch-main {
  border: 1px solid white;
  background: transparent;
}

.on-off-switch {
  color: white;
  display: flex;
  align-items: center;
  gap: 14px;
}

.unlock-p {
  font-size: 19px;
  padding-top: 4px;
}

.left-status-bar {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cam-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main__container {
  display: grid;
  height: calc(100% - 55px);
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr;
  gap: 10px 10px;
  position: relative;
  grid-auto-flow: row;
  grid-template-areas:
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "teleop__details video__stream video__stream"
    "teleop__details video__stream video__stream";
  transition: all 0.4s ease-in-out;
  /* overflow: auto; */
}

.main__container__toggle {
  display: grid;
  height: 100%;
  grid-template-columns: 20px auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr;
  gap: 10px 10px;
  position: relative;
  grid-auto-flow: row;
  grid-template-areas:
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "teleop__details video__stream video__stream"
    "teleop__details video__stream video__stream";
  /* justify-items: center; */
}

.video__stream {
  grid-area: video__stream;
  background-color: #292F40;
  border-radius: 7px;
  justify-self: center;
  position: relative;
  /* width: fit-content; */
  /* justify-content: end;
  display: flex; */
}

.map {
  display: flex;
  grid-area: map;
  min-width: 250px;
  border-radius: 7px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.teleop__details {
  grid-area: teleop__details;
  /* background-color: #292F40; */
  border-radius: 7px;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* background: #292F40 0% 0% no-repeat padding-box; */
  /* border: 3px solid #735AB6; */
  border-radius: 7px;
  /* padding: 5px; */
  transition: all 0.3s ease-in-out;
}

.landscape__popup__container {
  display: none;
}

.MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none !important;
  background-color: #2b2a44 !important;
  color: white;
  border-radius: 7px;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 91%);
}

@media (orientation: portrait) {
  .landscape__popup__container {
    display: flex;
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) and (min-device-aspect-ratio: 2/1) {
  .landscape__popup__container {
    display: none;
  }
}

/* Navbar */
header {
  border-radius: 7px;
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
  background: #292f40 0% 0% no-repeat padding-box;
}

nav {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}

.nav__left {
  display: flex;
  align-items: center;
  flex-grow: 0;
}

.back__btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back__btn__icon {
  color: #bec4de;
  cursor: pointer;
}

.robot__detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.robot__id__container {
  min-width: 150px;
  display: flex;
  flex-direction: column;
}

.robot__id {
  text-align: left;
  font: normal normal bolder 14px/16px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: max-content;
}

.robot__date {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.72;
}

.robot__battery__details {
  display: flex;
  align-items: center;
}

.robot__status {
  text-align: right;
  font: normal normal bold 11px/16px Poppins;
  letter-spacing: 0px;
  color: #e96c67;
  opacity: 1;
}

.robot__battery__percentage {
  text-align: right;
  font: normal normal bold 12px/16px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.robot__battery__logo {
  margin-left: 5px;
}

.nav__center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

/* .robot__speed__details {
  min-width: 50px;
}

.robot__total__distance__details {
  min-width: 150px;
} */

.robot__speed__details,
.robot__ring__light__details,
.robot__head__light__details,
/* .robot__total__distance__details, */
.robot__slam__details,
.robot__stream__client__details,
.robot__restart__stream__details,
.robot__complexity__layers,
.robot__relocalisation__layer {
  display: flex;
  flex-direction: column;
  margin-inline: 4%;
}

.robot__speed__text,
.robot__ring__light__text,
.robot__head__light__text,
.robot__total__distance__text,
.robot__slam__text,
.robot__stream__client__text,
.robot__restart__stream__text,
.robot__complexity__layers__text,
.robot__relocalisation__layer__text {
  text-transform: uppercase;
  text-align: left;
  font: normal normal bold 0.60rem/16px Poppins;
  letter-spacing: 0px;
  color: #BEC4DE;
  opacity: 1;
}

.robot__speed,
.robot__total__distance {
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal normal 0.75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.robot__ring__light__switch,
.robot__head__light__switch,
.robot__stream__client__switch,
.robot__restart__stream__switch,
.robot__slam__switch,
.robot__complexity__layers__switch,
.robot__relocalisation__layer__switch {
  color: white;
  display: flex;
  align-items: center;
  gap: 14px;
}

.robot__ring__light__switch__main,
.robot__directions__switch__main,
.robot__head__light__switch__main,
.robot__unlock__switch__main,
.robot__stream__client__switch__main,
.robot__restart__stream__switch__main,
.robot__slam__switch__main,
.robot__complexity__layers__switch__main,
.robot__relocalisation__layer__switch__main {
  border: 1px solid white;
  background: transparent;
  width: 33px;
  height: 14px;
}

.ant-switch {
  min-width: 35px !important;
  height: 14px !important;
}

.ant-switch.ant-switch-checked {
  background: #00B7D4 !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: transparent !important;
}

.ant-switch .ant-switch-handle {
  position: absolute !important;
  top: 1px !important;
  inset-inline-start: 1px !important;
  width: 10px !important;
  height: 10px !important;
  transition: all 0.2s ease-in-out !important;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 12px) !important;
}

.nav__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
}

.reset__diagnostics__btn {
  min-width: 120px;
  width: 150px;
  height: 100%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #BBC1DC;
  border-radius: 5px;
  text-align: left;
  font: normal normal normal 0.75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  cursor: pointer;
  margin-inline: 3%;
}

.restart__stream__btn {
  min-width: 100px;
  width: 150px;
  height: 100%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #BBC1DC;
  border-radius: 5px;
  text-align: left;
  font: normal normal normal 0.75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  cursor: pointer;
}

.robot__e__stop,
.robot__release {
  min-width: 55px;
  width: 75px;
  height: 100%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-align: left;
  font: normal normal bold 0.75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  cursor: pointer;
}

.robot__e__stop {
  background: #E96C67 0% 0% no-repeat padding-box;
}

.robot__release {
  background: rgba(128, 128, 128, 0.868) 0% 0% no-repeat padding-box;
}

.robot__expandable__options {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 5px; */
  font-size: 22px;
  height: 100%;
  color: #BBC1DC;
  opacity: 1;
  cursor: pointer;
}

.menu__extra__options {
  font: normal normal normal 0.75rem/16px Poppins !important;
  letter-spacing: 0px;
  height: 35px;
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  align-items: center;
}

.menu__extra__options:nth-child(1) {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.menu__extra__options:nth-last-child(1) {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.menu__extra__options:hover {
  background-color: #292f40 !important;
}

.menu__extra__responsive {
  display: none !important;
}

.nav__right__extra__options {
  display: none !important;
}

@media screen and (max-width: 1275px) {

  .robot__head__light__details,
  .robot__ring__light__details {
    display: none;
  }

  .menu__extra__responsive {
    display: flex !important;
  }
}

@media screen and (max-width: 768px) {
  .robot__date {
    font-size: 9px;
  }

  .reset__diagnostics__btn,
  .robot__e__stop,
  .restart__stream__btn {
    display: none !important;
  }

  .nav__right__extra__options {
    display: flex !important;
  }
}

/* @media screen and (max-width: 425px) {
  .nav__right {
      display: none;
  }
} fit-content max-height*/

.robot__map {
  width: 100%;
  height: 100%;
  opacity: 1;
  height: 60%;
  border-radius: 7px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.sidebar__minimize__btn {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  width: 40px;
  position: absolute;
  /* top: calc((100vh - 50%) / 2); */
  height: 40px;
  border-radius: 20px;
  background: #735AB6;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  opacity: 0.5;
  /* visibility: hidden; */
}

.sidebar__minimize__btn:hover {
  opacity: 1;
}

.teleop__details:hover .sidebar__minimize__btn,
.map:hover .sidebar__minimize__btn {
  visibility: visible !important;
  /* opacity: 1; */
}

/* .sidebar__minimize__btn:hover {
  visibility: visible;
} */

/* .sidebar__container:hover .sidebar__minimize__btn {
  background-color: #735AB6;
} */

.sidebar__minimize__btn__icon {
  font-size: 24px;
}

.robot__teleop__details {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 40%; */
  background: #292F40 0% 0% no-repeat padding-box;
  border: 3px solid #735AB6;
  border-radius: 7px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.robot__teleop__details__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 5px; */
}

.robot__teleop {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 59%; */
  height: 40px;
  /* background: #735AB6 0% 0% no-repeat padding-box; */
  /* border: 2px solid #735AB6; */
  border-radius: 5px;
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.robot__teleop__ack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29%;
  height: 40px;
  border: 2px solid #E96C67;
  border-radius: 5px;
  text-align: center;
  font: normal normal bold .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.robot__resume__auto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29%;
  height: 40px;
  border: 2px solid #e9cd67;
  border-radius: 5px;
  text-align: center;
  font: normal normal bold .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
}

.robot__teleop__location {
  text-align: left;
  font: normal normal normal .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.robot__order__id {
  text-align: left;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  margin-bottom: 5px;
  color: #FFFFFF;
  opacity: 1;
}

.robot__actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
  /* margin-bottom: 10px; */
}

.robot__action {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #88CD6F 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 35px;
  width: 40%;
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.teleop__watching {
  display: flex;
  align-items: flex-end;
  text-align: center;
  font: normal normal normal .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.teleop__watching strong {
  margin: 0 3px;
  text-align: center;
  font: normal normal normal .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.watching__icon {
  color: #BEC4DE;
  width: 15px;
  height: 15px;
}

.users__info {
  width: 100%;
  height: 60%;
  min-width: 40px;
  background: #292f40 0% 0% no-repeat padding-box;
  border: 1px solid #bec4de;
  border-radius: 7px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.game__controller__status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.current__user {
  text-align: center;
  font: normal normal bold .70rem/16px Poppins;
  letter-spacing: 0px;
  color: #BEC4DE;
  opacity: 1;
}

.circle__icons {
  display: flex;
  flex-direction: row;
}

.circle__icon:not(:first-child) {
  margin-left: -6px;
}

.control__btn__text {
  text-align: center;
  font: normal normal bold .75rem/16px Poppins;
  letter-spacing: 0px;
  color: #8d91a3;
}

.controller__status {
  text-align: left;
  font: normal normal bold .65rem/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

/* .game__controller__status img {
  width: 30px;
  height: 30px;
} */

@media screen and (max-width: 1136px) {
  .robot__teleop {
    font-size: .65rem;
  }

  .robot__teleop__ack {
    font-size: .55rem;
  }

  .robot__resume__auto {
    font-size: .55rem;
  }

  .robot__teleop__location,
  .robot__order__id {
    font-size: 0.60rem;
  }

  .robot__action {
    font-size: 0.60rem;
  }

  /* .watching__icon {
      width: 15px;
      height: 15px;
  } */

  .teleop__watching {
    font-size: .60rem;
  }

  .teleop__watching strong {
    font-size: 0.70rem;
  }

  .robot__action {
    padding: 0px 10px;
  }

  .game__controller__status img {
    width: 20px;
    height: 20px;
  }

  .controller__status {
    font-size: 0.55rem;
  }

  .current__user {
    font-size: 0.60rem;
  }

  .main-circle {
    width: 20px;
    height: 20px;
  }

  .users__info {
    padding: 5px;
  }

  .inner-text {
    font-size: 0.50rem;
  }
}

.front__main__camera {
  color: white;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  opacity: 1;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: flex-start;
}

.cam__video {
  height: 100%;
  max-width: fit-content;
  max-height: fit-content;
  width: 100%;
  object-position: center;
  border-radius: 10px;
}

.unlock__robot__controls {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlock__robot__controls__text {
  text-transform: uppercase;
  font: normal normal bold 0.65rem/16px Poppins;
  letter-spacing: 0px;
  color: #BEC4DE;
  opacity: 1;
}

.unlock__robot__controls__container {
  padding-inline: 10px;
  display: flex;
  width: 100%;
  height: 4%;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.75rem;
}

.main-circle {
  background: #bec4de;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 2px solid #ffffff;
  position: relative;
  margin: 0px;
  padding: 0px;
  transition: 0.5s;
}

.inner-text {
  margin: 0px;
  color: #1c1e2b;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  /* padding-top: 4px; */
  font: normal normal bold 0.75rem/16px Poppins;
}

.main-circle:hover {
  margin-top: -5px;
  transition: 0.1s ease-in-out;
  z-index: 100;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100px;
  color: #1c1e2b;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal .75rem/16px Poppins;
  background: #bec4de 0% 0% no-repeat padding-box;
  opacity: 1 !important;
}

.hover-text:hover .tooltip {
  visibility: visible;
}

#bottom {
  top: 35px;
  left: -157%;
}

.hover-text {
  position: relative;
  text-align: center;
}

.extended-screen-control {
  bottom: 8px;
  position: absolute;
  right: 145px;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 0;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 18px;
  color: #fff;
  background: transparent;
  border-radius: 3px;
  padding: 6px;
  pointer-events: auto;
  transition: all 0.3s ease 0s;
  transform: scale(1, 1);
  touch-action: manipulation;
  box-sizing: border-box;
}

.extended-screen-control span {
  font-size: 22px;
}

.extended-screen-control:hover {
  background: rgba(255, 255, 255, 0.27);
  color: #fff;
  transform: scale(calc(1 + 0.06));
}

.video__stream:hover .custom__video_controls_container {
  visibility: visible;
}

.extended__screen_container .custom__video_controls_container {
  visibility: visible;
}

.custom__video_controls_container {
  position: absolute;
  display: flex;
  visibility: hidden;
  width: max-content;
  gap: 5px;
  bottom: 5px;
  right: 5px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-delay: 150ms;
}

.custom__video_controls {
  /* Reset box model properties */
  padding: 0;
  border: none;
  margin: 0;

  /* Reset font properties */
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  /* Reset background and text colors */
  background-color: transparent;
  color: inherit;

  /* Reset text alignment */
  text-align: left;

  /* Reset other visual properties */
  outline: none;
  cursor: pointer;
}

.custom__controls_icons {
  width: 28px;
  height: 28px;
  color: white;
}

.stream__status__wrapper {
  position: absolute;
  top: 5px;
  right: 10px;
  /* z-index: 99999; */
}

.stream__status {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  background-color: #363a42a3;
  border-radius: 10px;
}

.stream__status_text {
  font-size: 14px;
  color: white;
  font-weight: 600;
}

.stream__status_live {
  color: red;
  width: 20px;
  height: 20px;
}

.stream__status_not_live {
  color: #ffffff7a;
  width: 20px;
  height: 20px;
}

.stream__status_person_count_icon {
  color: white;
  width: 20px;
  height: 20px;
}

.stream__stats_table {
  padding: 3px 16px !important;
}

.extended__screen_container {
  height: 100vh;
  width: 100%;
  display: flex;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  object-position: center;
}

.main__container__without_stream {
  display: grid;
  height: calc(100% - 55px);
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 10px;
  position: relative;
  grid-auto-flow: row;
  grid-template-areas:
    "teleop__details map map"
    "teleop__details map map"
    "teleop__details map map";
  transition: all 0.4s ease-in-out;
  /* overflow: auto; */
}

.robot__teleop__details__container {
  display: flex;
  flex-direction: column;
}

/* FLEET PAGE CSS */

.fleet__card {
  width: max-content;
  display: flex;
  flex-direction: column;
  background: #292F40;
  padding: 15px;
  border-radius: 8px;
  width: 33.33%;
  gap: 20px;
  min-width: 330px;
}

.fleet__card_name {
  color: #00B7D4 !important;
  text-align: left !important;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
}

.fleet__card_details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.fleet__card__robot_details {
  width: 100%;
  cursor: pointer;
  border-top: none !important;
  border-radius: 8px;
  position: relative !important;
  background: #1C1E2A;
}

.fleet__card__robot_details_container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.fleet__card__robot_details_robot_status_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
}

.fleet__card__robot_details_robot_status {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  /* gap: 5px; */
}

.fleet__card__robot_details_robot_id {
  color: white;
}

.fleet__card__robot_details_robot_battery_container {
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fleet__card__robot_details_robot_battery_details {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
}

.fleet__card__robot_details_robot_battery_details p {
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  font-weight: 800;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.batter_percentage {
  width: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px;
  height: 14px;
  display: inline-block;
}

.battery_icon {
  width: 35px;
  position: relative;
}

.battery_icon:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0px;
}

.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  color: white !important;
  font-size: 12px;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.No_video_available_div {
  background-color: #282F42;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.No_video_available_div p {
  color: white;
  font-size: 17px;
}

/* .teleopMoving_bordertop {
  border-top: 8px solid #7859bc;
} */

.teleopMoving_bordertop_p {
  color: white;
}

.teleopMoving_bordertop_p span.state_border::after {
  background: #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoRun_moving {
  border-top: 8px solid #40B4D0
} */

.AutoRun_moving_p span.state_border::after {
  background: #40B4D0;
}

.AutoRun_moving_p {
  color: white;
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoObstacle_teleop_obstacle_bordertop {
  border-top: 8px solid #e6883c;
} */

.AutoObstacle_teleop_obstacle_p {
  color: white;
}

.AutoObstacle_teleop_obstacle_p span.state_border::after {
  background: #e6883c;
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoSystem_fault_error_bordertop {
  border-top: 8px solid rgb(228 51 41);
} */

.AutoSystem_fault_error_p {
  color: white !important;

}

span.state_border {
  display: inline-block;
  position: relative;
}

span.state_border::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
}

.AutoSystem_fault_error_p span.state_border::after {
  background: rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .payloadState_bordertop {
  border-top: 8px solid #f0b156;
} */

.payloadState_bordertop_p {
  color: white;
}

.payloadState_bordertop_p span.state_border::after {
  background: #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .Teleop_autoRun_borderTop {
  border-top: 8px solid rgb(113, 207, 98);
} */

.Teleop_autoRun_p {
  color: white;
}

.Teleop_autoRun_p span.state_border::after {
  background: rgb(113, 207, 98);
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
}

/* .defaultBorderTop {
  border-top: 8px solid #a2a2a2;
} */

.defaultBorderTop_P {
  color: white;
}

.defaultBorderTop_P span.state_border::after {
  background: #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
  word-break: break-word;
}

.red-dot {
  background-color: #FF6767;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
}

.green-dot {
  background-color: #88F976;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.d-flex-center.Dasboard_page_individual_robot_box_2 {
  position: relative !important;
}

.yellow-dot {
  background-color: #FFB800;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes dotanimation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.battery__swap {
  width: 100%;
  height: 25px;
  background: #FF6767;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 15px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.battery__percentage {
  width: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px;
  height: 14px;
  display: inline-block;
}

.fleet__details__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.fleet__details__container.border-red {
  justify-content: flex-start;
  width: 407px;
  margin: 5px;
  background-color: rgb(40, 47, 66);
}

.fleet__details__container .Dashboard-content-div-one-left-side-h3 {
  text-align: flex-start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
}

.fleet__details__container .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

/* // From: Pitch toggle control for Mapbox GL JS — http://fuzzytolerance.info/blog/2017/01/30/Pitch-toggle-control-for-Mapbox-GL-JS/ */
.mapboxgl-ctrl-pitchtoggle-3d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==) !important;
}

.mapboxgl-ctrl-pitchtoggle-2d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==) !important;
}

.components {
  display: flex;
  color: white;
}

.joystick-div {
  width: 50%;
}

.form-div {
  width: 50%;
}

.mapboxgl-popup-close-button {
  padding: 1px 6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}